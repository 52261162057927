var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-row" }, [
    _c("div", { staticClass: "flex-row-fluid" }, [
      _c(
        "div",
        { staticClass: "card card-custom position-relative" },
        [
          _vm.showLoader ? _c("LoaderPartial") : _vm._e(),
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "div",
              { staticClass: "card-title align-items-start flex-column" },
              [
                _vm.$route.params.id
                  ? _c(
                      "h3",
                      {
                        staticClass: "card-label font-weight-bolder text-dark"
                      },
                      [_vm._v(" " + _vm._s(_vm.user.name) + " ")]
                    )
                  : _c(
                      "h3",
                      {
                        staticClass: "card-label font-weight-bolder text-dark"
                      },
                      [_vm._v(" Nuevo Administrador ")]
                    ),
                _vm.$route.params.id
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "text-muted font-weight-bold font-size-sm mt-1"
                      },
                      [_vm._v("Actualiza la información del usuario")]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "text-muted font-weight-bold font-size-sm mt-1"
                      },
                      [_vm._v("Completa la información del usuario")]
                    )
              ]
            ),
            _c("div", { staticClass: "card-toolbar" }, [
              !!_vm.user.id
                ? _c(
                    "button",
                    {
                      ref: "kt_save_changes",
                      staticClass: "btn btn-success mr-2",
                      attrs: {
                        type: "reset",
                        disabled: _vm.$v.editUser.$invalid
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(" Actualizar ")]
                  )
                : _vm._e(),
              !_vm.user.id
                ? _c(
                    "button",
                    {
                      ref: "kt_save_changes",
                      staticClass: "btn btn-success mr-2",
                      attrs: { type: "reset", disabled: _vm.$v.user.$invalid },
                      on: {
                        click: function($event) {
                          return _vm.create()
                        }
                      }
                    },
                    [_vm._v(" Crear ")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "reset", href: "#/users" }
                },
                [_vm._v(" Cancelar ")]
              )
            ])
          ]),
          _c("form", { staticClass: "form" }, [
            _c("div", { staticClass: "card-body" }, [
              _vm._m(0),
              !!_vm.user.id
                ? _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "col-xl-3 col-lg-3 col-form-label text-right"
                      },
                      [_vm._v("ID")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-9 col-xl-6",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "flex-direction": "column"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.user.id) + " ")]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                  class: { "form-group--error": _vm.$v.user.name.$error }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-xl-3 col-lg-3 col-form-label text-right"
                    },
                    [_vm._v("Nombre completo")]
                  ),
                  _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.user.name.$model,
                          expression: "$v.user.name.$model",
                          modifiers: { trim: true }
                        }
                      ],
                      ref: "name",
                      staticClass:
                        "form-control form-control-lg form-control-solid",
                      attrs: { type: "text" },
                      domProps: { value: _vm.$v.user.name.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.user.name,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    !_vm.$v.user.name.required
                      ? _c("div", { staticClass: "error text-primary" }, [
                          _vm._v(" Este campo es obligatorio ")
                        ])
                      : _vm._e(),
                    !_vm.$v.user.name.minLength
                      ? _c("div", { staticClass: "error text-primary" }, [
                          _vm._v(" Son requeridos por lo menos 4 caracteres. ")
                        ])
                      : _vm._e()
                  ])
                ]
              ),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-xl-3 col-lg-3 col-form-label text-right"
                  },
                  [_vm._v("Alias")]
                ),
                _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.user.nickname.$model,
                        expression: "$v.user.nickname.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    ref: "nickname",
                    staticClass:
                      "form-control form-control-lg form-control-solid",
                    attrs: { type: "text" },
                    domProps: { value: _vm.$v.user.nickname.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.user.nickname,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  !_vm.$v.user.nickname.required
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" Este campo es obligatorio ")
                      ])
                    : _vm._e(),
                  !_vm.$v.user.nickname.minLength
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" Son requeridos por lo menos 5 caracteres. ")
                      ])
                    : _vm._e(),
                  !_vm.$v.user.nickname.notExists &&
                  !_vm.$v.user.nickname.$pending
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" El alias ingresado ya existe. ")
                      ])
                    : _vm._e(),
                  !_vm.$v.user.nickname.regex
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(
                          " El alias no puede contener espacios ni caracteres especiales. "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-xl-3 col-lg-3 col-form-label text-right"
                  },
                  [_vm._v("Correo Electrónico")]
                ),
                _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-group input-group-lg input-group-solid"
                    },
                    [
                      _vm._m(1),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.user.email.$model,
                            expression: "$v.user.email.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        ref: "email",
                        staticClass:
                          "form-control form-control-lg form-control-solid",
                        attrs: { type: "text", placeholder: "Email" },
                        domProps: { value: _vm.$v.user.email.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.user.email,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]
                  ),
                  !_vm.$v.user.email.required
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" Este campo es obligatorio ")
                      ])
                    : _vm._e(),
                  !_vm.$v.user.email.email
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(
                          " Debe ingresar una dirección de correo electrónico. "
                        )
                      ])
                    : _vm._e(),
                  !_vm.$v.user.email.notExists && !_vm.$v.user.nickname.$pending
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" El email ingresado ya existe. ")
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-xl-3 col-lg-3 col-form-label text-right"
                  },
                  [_vm._v("Rol de usuario")]
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-9 col-xl-6" },
                  [
                    _c("b-form-select", {
                      staticClass:
                        "mb-3 form-control form-control-solid form-control-lg",
                      attrs: {
                        options: _vm.roles,
                        "value-field": "id",
                        "text-field": "name",
                        "input-id": "type-basic",
                        "disabled-field": "notEnabled"
                      },
                      model: {
                        value: _vm.selectedRole,
                        callback: function($$v) {
                          _vm.selectedRole = $$v
                        },
                        expression: "selectedRole"
                      }
                    })
                  ],
                  1
                )
              ]),
              !_vm.user.id
                ? _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "col-xl-3 col-lg-3 col-form-label text-right"
                      },
                      [_vm._v("Contraseña")]
                    ),
                    _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.user.password.$model,
                            expression: "$v.user.password.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        ref: "password",
                        staticClass:
                          "form-control form-control-lg form-control-solid",
                        attrs: { type: "password" },
                        domProps: { value: _vm.$v.user.password.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.user.password,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      !_vm.$v.user.password.required
                        ? _c("div", { staticClass: "error text-primary" }, [
                            _vm._v(" Este campo es obligatorio ")
                          ])
                        : _vm._e(),
                      !_vm.$v.user.password.minLength
                        ? _c("div", { staticClass: "error text-primary" }, [
                            _vm._v(
                              " La contraseña debe ser de al menos 8 caracteres. "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              !_vm.user.id
                ? _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "col-xl-3 col-lg-3 col-form-label text-right"
                      },
                      [_vm._v("Repetir contraseña")]
                    ),
                    _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.user.repeatPassword.$model,
                            expression: "$v.user.repeatPassword.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        ref: "repeatPassword",
                        staticClass:
                          "form-control form-control-lg form-control-solid",
                        attrs: { type: "password" },
                        domProps: { value: _vm.$v.user.repeatPassword.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.user.repeatPassword,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      !_vm.$v.user.repeatPassword.sameAsPassword
                        ? _c("div", { staticClass: "error text-primary" }, [
                            _vm._v(" Las contraseñas no coinciden. ")
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              !!_vm.user.id
                ? _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "col-xl-3 col-lg-3 col-form-label text-right"
                      },
                      [_vm._v("Fecha de creación")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-9 col-xl-6",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "flex-direction": "column"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.user.createdAt) + " ")]
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-xl-3" }),
      _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
        _c("h5", { staticClass: "font-weight-bold mb-6" }, [
          _vm._v("Perfil de administración")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-at" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }