<template>
  <div class="d-flex flex-row">

    <!--begin::Content-->
    <div class="flex-row-fluid">
      <!--begin::Card-->
      <div class="card card-custom position-relative">
        <LoaderPartial v-if="showLoader"></LoaderPartial>
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 v-if="$route.params.id" class="card-label font-weight-bolder text-dark">
              {{ user.name }}
            </h3>
            <h3 v-else class="card-label font-weight-bolder text-dark">
              Nuevo Administrador
            </h3>
            <span v-if="$route.params.id" class="text-muted font-weight-bold font-size-sm mt-1"
              >Actualiza la información del usuario</span
            >
            <span v-else class="text-muted font-weight-bold font-size-sm mt-1"
              >Completa la información del usuario</span
            >
          </div>
          <div class="card-toolbar">
            <button
              type="reset"
              class="btn btn-success mr-2"
              @click="save()"
              ref="kt_save_changes"
              v-if="!!user.id"
              :disabled="$v.editUser.$invalid"
            >
              Actualizar
            </button>
            <button
              type="reset"
              class="btn btn-success mr-2"
              @click="create()"
              ref="kt_save_changes"
              v-if="!user.id"
              :disabled="$v.user.$invalid"
            >
              Crear
            </button>
            <a type="reset" class="btn btn-secondary" href="#/users">
              Cancelar
            </a>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
          <!--begin::Body-->
          <div class="card-body">
            <div class="row">
              <label class="col-xl-3"></label>
              <div class="col-lg-9 col-xl-6">
                <h5 class="font-weight-bold mb-6">Perfil de administración</h5>
              </div>
            </div>
            <div class="form-group row" v-if="!!user.id">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >ID</label
              >
              <div
                class="col-lg-9 col-xl-6"
                style="display: flex;justify-content: center;flex-direction: column;"
              >
                {{ user.id }}
              </div>
            </div>
            <div
              class="form-group row"
              :class="{ 'form-group--error': $v.user.name.$error }"
            >
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Nombre completo</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  ref="name"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-model.trim="$v.user.name.$model"
                />
                <div class="error text-primary" v-if="!$v.user.name.required">
                  Este campo es obligatorio
                </div>
                <div class="error text-primary" v-if="!$v.user.name.minLength">
                  Son requeridos por lo menos 4 caracteres.
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Alias</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  ref="nickname"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-model.trim="$v.user.nickname.$model"
                />
                <div
                  class="error text-primary"
                  v-if="!$v.user.nickname.required"
                >
                  Este campo es obligatorio
                </div>
                <div
                  class="error text-primary"
                  v-if="!$v.user.nickname.minLength"
                >
                  Son requeridos por lo menos 5 caracteres.
                </div>
                <div
                  class="error text-primary"
                  v-if="
                    !$v.user.nickname.notExists && !$v.user.nickname.$pending
                  "
                >
                  El alias ingresado ya existe.
                </div>
                <div class="error text-primary" v-if="!$v.user.nickname.regex">
                  El alias no puede contener espacios ni caracteres especiales.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Correo Electrónico</label
              >
              <div class="col-lg-9 col-xl-6">
                <div class="input-group input-group-lg input-group-solid">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-at"></i>
                    </span>
                  </div>
                  <input
                    ref="email"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    v-model.trim="$v.user.email.$model"
                  />
                </div>
                <div class="error text-primary" v-if="!$v.user.email.required">
                  Este campo es obligatorio
                </div>
                <div class="error text-primary" v-if="!$v.user.email.email">
                  Debe ingresar una dirección de correo electrónico.
                </div>
                <div
                  class="error text-primary"
                  v-if="!$v.user.email.notExists && !$v.user.nickname.$pending"
                >
                  El email ingresado ya existe.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Rol de usuario</label
              >
              <div class="col-lg-9 col-xl-6">
                <b-form-select
                  v-model="selectedRole"
                  :options="roles"
                  class="mb-3 form-control form-control-solid form-control-lg"
                  value-field="id"
                  text-field="name"
                  input-id="type-basic"
                  disabled-field="notEnabled"
                ></b-form-select>
              </div>
            </div>
            <div class="form-group row" v-if="!user.id">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Contraseña</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  ref="password"
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  v-model.trim="$v.user.password.$model"
                />
                <div
                  class="error text-primary"
                  v-if="!$v.user.password.required"
                >
                  Este campo es obligatorio
                </div>
                <div
                  class="error text-primary"
                  v-if="!$v.user.password.minLength"
                >
                  La contraseña debe ser de al menos 8 caracteres.
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="!user.id">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Repetir contraseña</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  ref="repeatPassword"
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  v-model.trim="$v.user.repeatPassword.$model"
                />
                <div
                  class="error text-primary"
                  v-if="!$v.user.repeatPassword.sameAsPassword"
                >
                  Las contraseñas no coinciden.
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="!!user.id">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Fecha de creación</label
              >

              <div
                class="col-lg-9 col-xl-6"
                style="display: flex;justify-content: center;flex-direction: column;"
              >
                {{ user.createdAt }}
              </div>
            </div>
          </div>
          <!--end::Body-->
        </form>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers
} from "vuelidate/lib/validators";
import LoaderPartial from "@/view/content/LoaderPartial.vue";

const RESOURCE = "admins";
const cv = {
  user: {
    alphaNumAndDotValidator: helpers.regex("alphaNumAndDot", /^[a-z0-9._-]*$/i),
    async nicknameNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: { nickname: value }
        }
      });
      let id = window.location.hash.split("/")?.pop();
      return (
        resource.data.length == 0 ||
        (id != "new" && resource.data.pop()?.id == id)
      );
    },
    async emailNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: { email: value }
        }
      });
      let id = window.location.hash.split("/")?.pop();
      return (
        resource.data.length == 0 ||
        (id != "new" && resource.data.pop()?.id == id)
      );
    }
  }
};

export default {
  name: "UserProfile",
  components: {
    LoaderPartial
  },
  data() {
    return {
      resource: "admins",
      user: {
        name: "",
        nickname: "",
        email: "",
        password: "",
        repeatPassword: "",
        roles: [],
        
      },
      default_photo: "media/users/blank.png",
      current_photo: null,
      showLoader: true,
      roles: [],
      selectedRole: null,
    };
  },
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(4)
      },
      nickname: {
        required,
        minLength: minLength(5),
        notExists: cv.user.nicknameNotExists,
        regex: cv.user.alphaNumAndDotValidator
      },
      email: {
        required,
        email,
        notExists: cv.user.emailNotExists
      },
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs("password")
      },
    },
    editUser: ["user.name", "user.nickname", "user.email"]
  },
  async mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
    this.roles = await this.getRolesList();
    if (this.$route.params.id) {
      console.log("user existent");
      this.user = await this.getUser();
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios", route: "/users"}, {title: this.user.nickname}]);
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios", route: "/users"}, {title: "Perfil"}]);
    }
    this.showLoader = false;
  },
  methods: {
    async getResource(id) {
      let { data } = await ApiService.get(`${this.resource}`, id);
      //console.log(data);
      return data;
    },
    async getRolesList(){
      let {data} = await ApiService.get("admins/roles")
      return data
    },
    async getUser() {
      this.showLoader = true;
      let user;
      try {
        user = await this.getResource(this.$route.params.id);
        let selectedRole = this.roles.find(role => user.roles[0] == role.id)
        this.selectedRole = selectedRole?.id;
      } catch (e) {
        this.$router.push({ name: "404" });
      }
      this.showLoader = false;
      return user;
    },
    async create() {
      console.log(this.user);
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // dummy delay
      setTimeout(async () => {
        // send update request
        try {
          this.user.roles = [this.selectedRole];
          let { data } = await ApiService.post(`${this.resource}`, this.user);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          if (data.id) {
            this.user = data;
            this.$router.push({ path: `/users/${data.id}` });
          }

          Swal.fire({
            title: "",
            text: "El usuario ha sido guardado exitosamente!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }).then(function() {
            window.location.href = "#/users";
          });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        } catch (e) {
          let message =
            "Por favor revisa que los datos ingresados sean correctos";

          if (e.response.data) {
            message = e.response.data.error.message;
          }

          Swal.fire({
            title: "El usuario no ha sido creado",
            text: message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        }
      }, 500);
    },
    async save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // dummy delay
      setTimeout(async () => {
        // send update request
        try {
          let { id, ...currentUser } = Object.entries(this.user).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          );
          currentUser.roles = [this.selectedRole];
          await ApiService.patch(`${this.resource}/${id}`, currentUser);
          // this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          //   name,
          //   surname,
          //   company_name,
          //   phone,
          //   email,
          //   company_site,
          //   photo
          // });
          Swal.fire({
            title: "",
            text: "El usuario ha sido guardado exitosamente!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }).then(function() {
            window.location.href = "#/users";
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        } catch (e) {
          Swal.fire({
            title: "El usuario no ha sido creado",
            text: "Por favor revisa que los datos ingresados sean correctos",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        }
      }, 500);
    }
  },
  watch: {
    "user.nickname": {
      handler: function(nickname) {
        if (this.$route.params.id) {
          this.user.nickname = nickname.toLowerCase();
        }
      },
      immediate: true
    },
    '$route': {
      handler: async function() {
        if (this.$route.params.id) {
          // console.log(this.$route.params.id);
          this.user = await this.getUser();
          this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios", route: "/users"}, {title: this.user.nickname}]);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
